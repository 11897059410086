<template>
  <div class="games-grid__container">
    <GamesGridCard
        v-for="item of items"
        :class="{'loading': isLoading}"
        :image-url="item.icon_file"
        :text="item.title_text"
        :has-page="item.has_page"
        :page-url="item.page_url"
        :is-loading="isLoading"
        :preload="true"
    />
  </div>
</template>


<script>
import GamesGridCard from './games_grid_card'

export default {
    name: "GamesGrid",
    components: {
        GamesGridCard
    },
    props: {
        items: Array,
        isLoading: Boolean
    }
}
</script>
