<template>
  <main class="summary-page" :style="{'--content-margin-top': this.marginTop}">
    <div ref="header" class="summary-page__header">
      <a href="/" class="logo"></a>
      <div class='tabs no-print'>
        <ul>
          <li href="games"
              @click="onTabClick"
              :class="{'active': tab === 'games'}">GAMES</li>
          <!--li href="ranks"
              @click="onTabClick"
              :class="{'active': tab === 'ranks'}">RANKS</li>-->
          <li href="certificates"
              @click="onTabClick"
              :class="{'active': tab === 'certificates'}">CERTIFICATES</li>
          <li href="languages"
              @click="onTabClick"
              :class="{'active': tab === 'languages'}">LANGUAGES</li>
          <li href="currencies"
              @click="onTabClick"
              :class="{'active': tab === 'currencies'}">CURRENCIES</li>
        </ul>
      </div>
      <div class="_header-ext" :class="{'active': tab === 'ranks'}">
        <div class="rank-top-description">
          <div class="base-description">
            Based on Compound Points derived from 7 KPIs.Calculated by
            summing up game ranks across each KPI, taking to account weight
            of KPI group. Compound Points - Higher is Better.
            <div class="rank-legend">
              <span class="ranks-tab-help-control">Bets Weight - 130%%</span>
              <span class="ranks-tab-help-control">Players Weight - 20%%</span>
              <span class="ranks-tab-help-control">VIPs Weight - 10%%</span>
              <span class="ranks-tab-help-control">Rounds Weight - 50%%</span>
              <span class="ranks-tab-help-control">GGR Weight - 100%%</span>
              <span class="ranks-tab-help-control">Average Bet Weight - 50%%</span>
              <span class="ranks-tab-help-control">Average Rounds Weight - 50%%</span>
            </div>
          </div>
          <div class="ext-description" style="display: none;">
            <span class="blocked-games-legend"></span>
            <span class="blocked-games-legend-desc">
            - data-driven list of <b>lowPerforming games</b>, which generate
              not more than ~2.5%% from total volume, and their REMOVAL
              would help to:
            </span>
            <ul>
              <li>significantly increase the average quality of our portfolio</li>
              <li>improve the average player experience</li>
              <li>increase retention of your new and existing players</li>
              <li>let new players get into our brand via best games only in
                order to get the right experience and have a good feedback
                about our games in general</li>
              <li>reduce the bounce rate of NEW players entering via
                old/low-performing games</li>
            </ul>
          </div>
        </div>
        <div class="rank-filter-controls">
          <span class="tab-ext-head-control no-print">
              <label for="rank-geo-tag">Geo Tag:</label>
              <select name="rank-geo-tag" id="rank-geo-tag"></select>
          </span>
        </div>
      </div>
      <div class="_header-ext" :class="{'active': tab === 'certificates'}">
        <div class="legend-panel">
          <div>
            <span class="color-logo present"></span><span>available</span>
          </div>
          <div>
            <span class="color-logo absent"></span><span>not available</span>
          </div>
        </div>
      </div>
      <div class="_header-ext" :class="{'_active': tab === 'languages'}">
        <div class="legend-panel">
          <div>
            <span class="color-logo present"></span><span>available</span>
          </div>
          <div>
            <span class="color-logo absent"></span><span>not available</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-content">
      <div id="games" :class="{'active': tab === 'games'}">
        <SummaryTabGames v-if="shown.games" :user="user"/>
      </div>

      <div id="ranks" :class="{'active': tab === 'ranks'}">
        <div objname="rank" class="rank-grid"></div>
        <div objname="rank-blacked" class="rank-blacked-grid"></div>
      </div>

      <div id="certificates" :class="{'active': tab === 'certificates'}">
        <SummaryTabCertificates v-if="shown.certificates" :user="user"/>
      </div>

      <div id="languages" :class="{'active': tab === 'languages'}">
        <SummaryTabLanguages v-if="shown.languages" :user="user"/>
      </div>

      <div id="currencies" :class="{'active': tab === 'currencies'}">
        <SummaryTabCurrencies v-if="shown.currencies" :user="user"/>
      </div>
    </div>
    <Toolbar v-if="isToolbarVisible"/>
    <span class="spinner" data-content="loading..."></span>
  </main>
</template>


<script>
import { useHead } from '@vueuse/head';
import SummaryTabGames from './tab_games';
import SummaryTabLanguages from './tab_languages';
import SummaryTabCurrencies from './tab_currencies';
import SummaryTabCertificates from './tab_certificates';
import Toolbar from './toolbar';

export default {
    name: 'Summary',
    components: {
        SummaryTabGames,
        SummaryTabLanguages,
        SummaryTabCurrencies,
        SummaryTabCertificates,
        Toolbar
    },
    props: ['user'],
    data() {
        return {
            tab: 'games',
            marginTop: '0px',
            shown: {
                games: false,
                languages: false,
                certificates: false,
                currencies: false,
            }
        }
    },

    setup() {
        document.body.style.backgroundColor = '#fff';
        useHead({
            title: 'Summary - 3 Oaks Gaming'
        });
    },

    mounted () {
        this.tab = this.$route.query.tab || 'games';
        this.shown[this.tab] = true;
        this.calculateMarginTop();
        setTimeout(() => {
            this.calculateMarginTop();
        }, 0);
        window.addEventListener("resize", this.onResize);
    },

    destroyed () {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        isToolbarVisible() {
            return this.user.is_staff && this.tab === 'currencies';
        }
    },

    methods: {
        onTabClick(e) {
            this.tab = e.currentTarget.getAttribute('href');
            this.shown[this.tab] = true;

            this.$router.replace({
                query: { tab: this.tab }
            })
            this.calculateMarginTop();
            setTimeout(() => {
                this.calculateMarginTop();
            }, 0);
        },

        onResize(e) {
            this.calculateMarginTop();
        },

        calculateMarginTop() {
            this.marginTop = this.$refs.header.clientHeight + 'px';
        },

    },


}
</script>
