<template>
  <div class="games-grid__controls">

    <form action="#" class="search-form">
      <div class="search-form__grid">

        <SearchFormSelect
            v-for="filterName in selectFilersNamesList"
            :options="filtersOptions[filterName]"
            v-model="filters[filterName]"
            @update:model-value="(value) => onFilterUpdate(filterName, value)"
        />

        <div class="search-form__grid-item">
          <div class="search">
            <input
                autocomplete="off"
                type="text"
                name="form[]"
                placeholder="Search: By game`s name"
                class="search-form__input"
                v-model="searchInputValue"
            />
            <button type="submit" class="search-form__btn" @click.prevent="applyFilters"></button>
          </div>
        </div>

      </div>
    </form>

  </div>
  <div ref="gameGridContainer"></div>
  <div class="games-grid__container" v-if="items.length > 0">
    <GamesGridCard
        v-for="item of items"
        :class="{'loading': isGameCardLoading}"
        :image-url="item.icon_file"
        :guide-url="item.guide_url"
        :promo-pack-url="item.promo_pack_url"
        :release-date="item.release_date"
        :text="item.title_text"
        :has-page="item.has_page"
        :page-url="item.page_url"
        :preload="true"
    />
  </div>
  <div v-if="items.length === 0 && !isGameCardLoading">
    There are no games found for your search
  </div>
  <div>
    <Paging
        :total-pages="totalPages"
        :current-page="currentPage"
        @on-page-click="onPageClick"
    />
  </div>
</template>


<script>
import {scrollToElement} from "@/lib/common_utils";
import {appFetchJSON, processFetchError} from '@/lib/request_utils';
import EventBus from "@/lib/event_bus";
import GamesGridCard from './games_grid_card'
import Paging from "@/components/paging";
import SearchFormSelect from "@/components/forms/search-form-select";

const DOCUMENT_TYPE_PROMO_PACK = 'game_promo';
const DOCUMENT_TYPE_GUIDE = 'game_guide';
const FILTER_OPTION_ALL_MAPPING = {
    'game_category': 'All Games',
    'jackpot_type': 'All Jackpot Types'
}
const FILTER_TITLE_NOT_SET = 'Not Set';

export default {
    name: "GamesGrid",
    components: {
        GamesGridCard,
        Paging,
        SearchFormSelect,
    },

    data() {
        return {
            items: [],
            currentPage: 1,
            totalPages: 0,
            filters: {},
            filtersOptions: {},
            searchInputValue: '',
            isGameCardLoading: false,
        }
    },

    created() {
      this.scrollPromise = new Promise((resolve, reject) => resolve());
    },

    mounted() {
        this.getFiltersOptions().then(() => {
            this.updateDataFromRouteQuery();
            this.getGames();
        });
    },

    watch: {
        '$route.query'() {
            this.isGameCardLoading = true;
            this.updatePageNumFromRouteQuery();
            this.getGames().finally(() => {
                this.isGameCardLoading = false;
            });
        },
    },

    computed: {
        selectFilersNamesList() {
            return Object.keys(this.filtersOptions);
        }
    },

    methods: {
        getFiltersOptions() {
            return appFetchJSON(`/games/filters`)
                .then(res => {
                    const data = res.data || {};
                    for (const [filterName, filterOptionsData] of Object.entries(data)) {
                        if (!filterOptionsData || filterOptionsData.length === 0) {
                            continue;
                        }
                        const filterOptions = [];
                        filterOptionsData.forEach((item) => {
                            let title;
                            if (item) {
                                title = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
                            } else {
                                title = FILTER_TITLE_NOT_SET;
                            }
                            filterOptions.push({
                                'title': title,
                                'value': item,
                            })
                        })
                        filterOptions.sort((a, b) => {
                            if (a.title === FILTER_TITLE_NOT_SET) return -1;
                            if (b.title === FILTER_TITLE_NOT_SET) return 1;
                            if (a.title === b.title && a.title === FILTER_TITLE_NOT_SET) return 0;
                            if (a.title < b.title) return -1;
                            if (a.title > b.title) return 1;
                            return 0;
                        });
                        filterOptions.unshift(
                            {
                                title: FILTER_OPTION_ALL_MAPPING[filterName] || 'All',
                                value: null,
                            }
                        );
                        this.filtersOptions[filterName] = filterOptions;
                        this.filters[filterName] = filterOptions[0].value;
                    }
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        getGames() {
            let pageItemsNum = 10;
            const innerWidth = window.innerWidth;
            if (innerWidth >= 839 && innerWidth < 1144) {
                pageItemsNum = 9;
            } else if (innerWidth >= 1144 && innerWidth < 1920) {
                pageItemsNum = 12;
            } else if (innerWidth >= 1920) {
                pageItemsNum = 15;
            }

            const params = {
                'page_num': this.currentPage,
                'page_items_num': pageItemsNum,
                'game_name': this.searchInputValue,
            };

            for (const [filterName, filterValue] of Object.entries(this.filters)) {
                if (filterValue !== null && filterValue !== undefined) {
                    params[filterName] = filterValue;
                }
            }

            return appFetchJSON(`/games/client_area`, {params: params})
                .then(res => {
                    const items = res.data?.items || [];
                    items.forEach((item) => {
                        if (item['has_page']) {
                            const routeName = this.$route.name;
                            const targetRouteName = routeName === 'client-area-games' ? 'client-area-game' : 'game';
                            const routeParams = {name: targetRouteName, params: {name: item.name}};
                            item['page_url'] = this.$router.resolve(routeParams).fullPath;
                        }
                        item['guide_url'] = item.documents?.[DOCUMENT_TYPE_GUIDE];
                        item['promo_pack_url'] = item.documents?.[DOCUMENT_TYPE_PROMO_PACK];
                        // item['preloaded_icon'] = new Image();
                    });
                    this.items = items;
                    this.totalPages = res.data['total_pages'];
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        updatePageNumFromRouteQuery() {
            this.currentPage = Number(this.$route.query.page || 1);
        },

        updateDataFromRouteQuery() {
            this.updatePageNumFromRouteQuery();

            const routeQuery = this.$route.query || {};

            this.searchInputValue = routeQuery['game_name'] || '';

            const filters = this.filters;
            this.selectFilersNamesList.forEach((filterName) => {
                let filterOptions = this.filtersOptions[filterName];
                if (filterOptions) {
                    let routeQueryValue = routeQuery[filterName];
                    if (routeQueryValue !== undefined) {
                        let filterOptions = this.filtersOptions[filterName] || [];
                        filterOptions.forEach((item) => {
                            if (item.value === routeQueryValue) {
                                filters[filterName] = item.value;
                            }
                        });
                    }
                }
            });
        },

        onPageClick(pageNum) {
            if (!pageNum || pageNum === 1) {
                pageNum = undefined;
            }
            const query = {...this.$route.query || {}, page: pageNum};
            this.scrollPromise = scrollToElement(this.$refs.gameGridContainer, 0)
                .then(() => {
                    EventBus.emit(EventBus.EVENTS.HEADER_HIDE);
                });
            this.reload(query);
        },

        applyFilters() {
            const filtersQuery = {};

            const searchInputValue = this.searchInputValue;
            if (searchInputValue) {
                filtersQuery['game_name'] = searchInputValue;
            }

            for (const [filterName, filterValue] of Object.entries(this.filters)) {
                if (filterValue !== null && filterValue !== undefined) {
                    filtersQuery[filterName] = filterValue;
                }
            }

            this.reload(filtersQuery);
        },

        reload(query) {
            const routeData = {
                name: this.$route.name,
                query: query,
            };
            this.$router.replace(routeData);
        },

        onFilterUpdate(name, value) {
            this.applyFilters();
        }
    },

}
</script>
