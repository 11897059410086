<template>
  <div class="news-card">
    <div class="badges" v-if="showBadges">
      <span class="badge" :class="{active : showInPublicArea}">public area</span>
      <span class="badge" :class="{active : showInClientArea}">client area</span>
    </div>

    <div class="news-card__image _ibg">
      <a :href="articleUrl" ref="image-container">
        <img :src="imageUrl" alt="3oaks">
      </a>
    </div>

    <div class="news-card__tags">
      <ul>
        <li>{{ category }}</li>
        <li>
          <DateWidget :iso-date-string="publishDate"/>
        </li>
      </ul>
    </div>

    <div class="news-card__description">
      <h5>{{ title }}</h5>
    </div>

    <a
        class="news-card__read-more _icon-arrow-2"
        :href="articleUrl">
      READ MORE
    </a>

  </div>
</template>

<script>

import {loadAndAppendImageAnimated} from "@/lib/common_utils";
import DateWidget from "@/components/date_widget";

export default {
    name: "NewsCard",
    components: {
        DateWidget,
    },
    props: {
        title: String,
        imageUrl: String,
        category: String,
        publishDate: String,
        articleUrl: String,
        showInClientArea: Boolean,
        showInPublicArea: Boolean,
        showBadges: Boolean,
        preload: false,
    },

    data() {
        return {
            preloadedImg: new Image(),
        }
    },

    watch: {
        imageUrl(oldV, newV) {
            this.updateImg();
        }
    },

    mounted() {
        this.updateImg();
    },

    methods: {
        updateImg() {
            let imgContainerEl = this.$refs["image-container"];
            imgContainerEl.innerHTML = '';
            if (this.preload) {
                loadAndAppendImageAnimated(imgContainerEl, this.preloadedImg, this.imageUrl);
            } else {
                this.preloadedImg.src = this.imageUrl;
                imgContainerEl.appendChild(this.preloadedImg);
            }
        }
    }
}
</script>

<style scoped>

</style>