<template>
  <div class="game-card">
    <div class="game-card__body">
      <a
          :class="{'disabled': !hasPage}"
          :href="pageUrl"
          class="game-card__image _ibg" ref="image-container">
<!--        <img :src="imageUrl" alt="3oaks">-->
      </a>
      <div class="game-card__info">
        <div>
            <span>
              <DateWidget :iso-date-string="releaseDate"/>
            </span>
        </div>
        <div class="game-card__download-links">
          <a
              v-if="guideUrl"
              :href="guideUrl"
              class="_icon-download">
            Guide
          </a>
          <a
              v-if="promoPackUrl"
              :href="promoPackUrl"
              class="_icon-download">
            Promo
          </a>
        </div>
      </div>
      <a
          :class="{'disabled': !hasPage, '_icon-arrow-3': hasPage}"
          :href="pageUrl"
          class="game-card__description">
        <p v-html="text"></p>
      </a>
    </div>
  </div>
</template>


<script>
import {loadAndAppendImageAnimated} from "@/lib/common_utils";
import DateWidget from "@/components/date_widget";

export default {
    name: "GamesGridCard",
    components: {
        DateWidget,
    },
    props: {
        imageUrl: String,
        text: String,
        hasPage: Boolean,
        pageUrl: String,
        releaseDate: String,
        guideUrl: String,
        promoPackUrl: String,
        preload: false,
    },

    data() {
        return {
            preloadedImg: new Image(),
        }
    },

    watch: {
        imageUrl(oldV, newV) {
            this.updateImg();
        }
    },

    mounted() {
        this.updateImg();
    },

    methods: {
        updateImg() {
            let imgContainerEl = this.$refs["image-container"];
            imgContainerEl.innerHTML = '';
            if (this.preload) {
                loadAndAppendImageAnimated(imgContainerEl, this.preloadedImg, this.imageUrl);
            } else {
                this.preloadedImg.src = this.imageUrl;
                imgContainerEl.appendChild(this.preloadedImg);
            }
        }
    }
}
</script>
