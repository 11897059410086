<template>
    <section class="header-section">
        <div class="header-section__bg" :style="bannerStyle">
            <div class="header-section__title">
                <span v-html="tournamentTitle"></span>
            </div>

        </div>
        <TournamentLoginFormComponent
            v-if="showLoginForm"
            :player="player"
            :tournament="tournament"
        />
        <TournamentTimePlateComponent
            :tournament="tournament"
            :date-locale="dateLocale"
            :date-time-zone="dateTimeZone"
        />
    </section>
</template>

<script>
import TournamentTimePlateComponent from "@/pages/tournament/components/tournament_time_plate_component";
import TournamentLoginFormComponent from "@/pages/tournament/components/tournament_login_form_component";

export default {
    name: "TournamentHeaderSection",
    props: {
        tournament: {},
        player: {},
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        },

        showLoginForm: {
            type: Boolean,
            default: true,
        }
    },

    components: {
        TournamentTimePlateComponent,
        TournamentLoginFormComponent,
    },

    computed: {
        tournamentTitle() {
            return this.tournament?.title || '3OAKS Tournament';
        },

        bannerStyle() {
            const bannerURL = this.tournament.banner_url;
            if (bannerURL) {
                return `background-image: radial-gradient(50% 50.04% at 50% 50.04%, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.80) 100%), url("${bannerURL}");`;
            }
            return ''
        },
    }
}
</script>

