<template>
    <section class="games-section" v-if="currentGame !== undefined">
        <div class="section-title">
            <div class="section-title__line"></div>
            <div class="section-title__title">GAMES</div>
            <div class="section-title__line"></div>
        </div>

        <div class="games-section__content">
            <div class="games-section__qr-code">
                <img
                    v-if="tournament?.qr_code_url"
                    :src="tournament.qr_code_url"
                />
                <div v-else class="no-img">QR Code</div>
                <span>3oaks.com/games</span>
            </div>
            <div class="games-section__game-banner">
                <!-- <img :src="currentGame.banner_file"/> -->
                <div
                    class="_swiper swiper"
                    ref="swiperBanner"
                >
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide slide"
                            v-for="img in currentGameGalleryImages"
                        >
                            <img :src="img" />
                        </div>
                    </div>
                </div>
                <!-- <img :src="currentGame.gallery_images[0]"/> -->
            </div>

            <div class="games-section__games-slider">
                <div
                    class="_swiper swiper"
                    ref="swiper"
                    v-if="games && games.length > 1"
                >
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide slide"
                            v-for="game in games"
                        >
                            <img
                                :src="game.banner_file"
                                :class="{'active': game.name === currentGame.name}"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>

import Swiper from "@/assets/js/Swiper";
import {nextTick} from "vue";

export default {
    name: "TournamentGamesListSection",
    props: {
        tournament: {},
    },

    data() {
        return {
            currentGame: undefined,
            currentGameGI: [],
            swiperOneRowMode: false,
        }
    },

    watch: {
        'tournament.main_game'() {
            this.initCurrentGame();
        },

        'tournament.games'() {
            this.initSlider();
        }
    },

    mounted() {
        this.initCurrentGame();
        this.initSlider();
    },

    computed: {
        games() {
            let tGames = [...this.tournament?.games || []];
            // // mock
            // if (tGames.length) {
            //     for (let l = 0; l < 30; l++) {
            //         tGames.push(tGames[l]);
            //     }
            // }

            while (tGames.length > 0 && tGames.length < 10) {
                tGames = tGames.concat(tGames);
            }

            return tGames;
        },

        currentGameGalleryImages() {
            const galleryImages = [...this.currentGame?.gallery_images || []];
            if (!galleryImages || galleryImages.length === 0) {
                return [this.currentGame.banner_file];
            }
            return galleryImages;
        }

    },

    methods: {

        initCurrentGame() {
            if (this.currentGame === undefined) {
                const mainGame = this.getMainGame();
                this.setCurrentGame(mainGame);
            }
        },

        getMainGame() {
            const tnmt = this.tournament || {};
            const tGames = tnmt.games || [];

            let mainGame = tnmt['main_game'];
            if (mainGame) {
                for (let i = 0; i < tGames.length; i++) {
                    let tGame = tGames[i];
                    if (tGame.name === mainGame.name) {
                        return tGame;
                    }
                }
            }

            if (tGames.length > 0) {
                mainGame = tGames[0];
            }
            return mainGame;
        },

        setCurrentGame(game) {
            this.currentGame = game;
        },

        initSlider() {
            if (this.swiper) {
                this.swiper.destroy();
            }
            nextTick(() => {
                let initialSlide = 0;
                if (this.currentGame) {
                    initialSlide = this.games.indexOf(this.currentGame);
                }
                this.swiper = new Swiper(
                    this.$refs.swiper,
                    {
                        observer: true,
                        observeParents: true,
                        initialSlide: initialSlide,
                        slidesPerView: 2,
                        simulateTouch: true,
                        grabCursor: true,
                        direction: "vertical",
                        centeredSlides: true,
                        loop: true,
                        updateOnWindowResize: true,
                        loopSlides: 30,

                        on: {
                            activeIndexChange: this.onSliderActiveIndexChange,
                        },
                        autoplay: {
                            delay: 20000,
                            disableOnInteraction: false,
                        },
                    }
                );
            });
        },

        onSliderActiveIndexChange(swiper) {
            const gamesLength = this.games.length;
            if (gamesLength === 0) {
                this.currentGame = undefined;
            } else {
                let activeSlideIndex = swiper.realIndex;
                activeSlideIndex = activeSlideIndex < gamesLength - 1 ? activeSlideIndex : 0;
                this.currentGame = this.games[activeSlideIndex];
            }

            this.initSliderBanner();
        },

        initSliderBanner() {
            if (this.swiperBanner) {
                this.swiperBanner.destroy();
            }

            let autoplay = false;
            if (this.currentGameGalleryImages.length > 1) {
                autoplay = {
                    delay: 2500,
                    disableOnInteraction: false,
                }
            }

            this.swiperBanner = new Swiper(
                this.$refs.swiperBanner,
                {
                    observer: false,
                    observeParents: false,
                    slidesPerView: 1,
                    simulateTouch: false,
                    grabCursor: false,
                    loop: true,
                    updateOnWindowResize: true,
                    autoplay: autoplay,
                }
            );
        },

    }
}
</script>

